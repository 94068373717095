<template>
  <div
    :class="[
      'relative flex flex-col ',
      isProfileMenuExtended ? 'router-link-exact-active' : '',
    ]"
  >
    <div
      class="flex cursor-pointer p-3 py-1 pr-4 hover:text-action"
      data-testid="dropdown-menu"
      @click="toggleDropdownProfileMenu"
    >
      <!-- User avatar -->
      <user-avatar
        :size="2"
        class="mx-auto flex-shrink-0 border"
        :avatar="user.avatarUrl"
      />
      <!-- small arrow -->
      <div class="absolute top-5 right-0">
        <fv-icon
          icon="small-chevron-down"
          :class="[
            'svg-fill h-3 w-3 ',
            isProfileMenuExtended ? 'text-blue-700' : '',
          ]"
        />
      </div>
    </div>

    <!-- drop down -->
    <ProfileDropdown
      v-show="isProfileMenuExtended"
      @close-profile-menu="toggleDropdownProfileMenu"
    />

    <!-- out of focus div -->
    <div
      v-show="isProfileMenuExtended"
      class="bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out"
      @click="isProfileMenuExtended = false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import UserAvatar from '@/components/user/UserAvatar';
import ProfileDropdown from '@/components/interface/ProfileDropdown';

export default {
  components: {
    ProfileDropdown,
    UserAvatar,
  },
  props: {},
  data() {
    return {
      isProfileMenuExtended: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    toggleDropdownProfileMenu() {
      this.isProfileMenuExtended = !this.isProfileMenuExtended;
    },
  },
};
</script>

<style scoped></style>
