<template>
  <nav
    class="absolute top-0 z-50 mt-10 w-full rounded-lg border-t border-gray-200 bg-white py-3 shadow"
    style="min-width: 412px"
  >
    <ul class="flex flex-col">
      <!-- ===== start admin buttons ==== -->
      <component
        :is="getComponentType(navButtonDef)"
        v-for="(navButtonDef, index) of navDefinitions"
        v-show="getValueFromDefinitionKey(navButtonDef, 'isDisplayed')"
        :key="index"
        :class="navButtonDef.htmlClasses"
        exact-active-class="admin-menu-item-active"
        :to="navButtonDef.toSlug ? { name: navButtonDef.toSlug } : ''"
        :data-testid="navButtonDef.toSlug"
        @click="callClickFunction(navButtonDef)"
      >
        <fv-icon v-if="navButtonDef.icon" :icon="navButtonDef.icon" />
        <span class="ml-2">{{ navButtonDef.linkText }}</span>
        <span
          v-if="navButtonDef.isNew"
          class="ml-2 rounded-lg bg-azure-25 p-1 text-xs uppercase text-navy no-underline shadow"
          >New</span
        >
        <span
          v-if="navButtonDef.isUpdated"
          class="ml-2 rounded-lg bg-azure-25 p-1 text-xs uppercase text-navy no-underline shadow"
          >Updated</span
        >
      </component>
      <!-- ===== end admin buttons ==== -->
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import navDefinitions from '@/config/admin-nav-buttons';
import { __DEV__ } from '@/constants';
import { getValueFromDefinitionKey } from '@/config/definitionUtils';

export default {
  data() {
    return {
      navDefinitions,
    };
  },

  computed: {
    ...mapState('user', ['user']),

    showReleasesOption() {
      const releaseEnabledEmails = [
        'emma@firstvet.com',
        'pontus@firstvet.com',
        'emelie@firstvet.com',
        'niklas@firstvet.com',
        'fredrik.muntzing@firstvet.com',
        'marcus.hong@firstvet.com',
      ];
      return this.user && releaseEnabledEmails.includes(this.user.email);
    },

    isDev() {
      return __DEV__;
    },

    canManageClinicListing() {
      return this.$can('manage', 'ClinicListing');
    },

    canManageAll() {
      return this.$can('manage', 'All');
    },

    isClinicsButtonDisplayed() {
      return this.canManageAll;
    },

    isReleasesButtonDisplayed() {
      return this.canManageAll && this.showReleasesOption;
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    getComponentType(navButtonDef) {
      return navButtonDef.onClick ? 'button' : 'router-link';
    },

    async goto(navButtonDef) {
      await this.$emit('close');
      if (navButtonDef.toSlug) {
        this.$router.push({
          name: navButtonDef.toSlug,
        });
      }
    },

    async signOut() {
      await this.logout();
      await this.$router.push({
        name: 'login',
        params: { platform: localStorage.getItem('platform') },
      });
    },
    callClickFunction(navButtonDef) {
      if (
        navButtonDef.onClick &&
        this[navButtonDef.onClick] instanceof Function
      ) {
        this[navButtonDef.onClick](navButtonDef);
      }
    },
    getValueFromDefinitionKey(...args) {
      return getValueFromDefinitionKey.call(this, ...args);
    },
  },
};
</script>
