<template>
  <div class="py-1">
    <div class="inline-flex items-center">
      <div class="inline-flex space-x-2">
        <div class="relative">
          <button
            class="focus:outline-none inline-flex items-center rounded py-2 px-2 text-gray-900 hover:text-action"
            data-testid="admin-menu"
            @click="menuIsOpen = !menuIsOpen"
          >
            <div class="inline-flex items-center space-x-1">
              <span class="text-sm">Menu</span>
              <fv-icon
                icon="small-chevron-down"
                :class="['svg-fill h-3 w-3']"
              />
            </div>
          </button>
          <admin-navbar v-show="menuIsOpen" @close="closeAdminMenu" />
        </div>
      </div>
    </div>
    <div class="inline-flex items-center">
      <country-selector class="ml-2" />
    </div>

    <div
      v-show="menuIsOpen"
      class="bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out"
      @click="menuIsOpen = false"
    />
  </div>
</template>

<script>
import { FvIcon } from '@/UI/icons';
import { mapActions, mapState } from 'vuex';
import CountrySelector from '@/components/admin/CountrySelector';
import AdminNavbar from '@/components/admin/AdminNavbar';

export default {
  components: {
    CountrySelector,
    AdminNavbar,
    FvIcon,
  },
  data() {
    return {
      menuIsOpen: false,
    };
  },
  computed: {
    ...mapState('user', ['user']),
  },
  async mounted() {
    if (!this.country) {
      this.fetchCountries();
    }
  },
  methods: {
    ...mapActions('country', ['fetchCountries']),
    closeAdminMenu() {
      this.menuIsOpen = false;
    },
  },
};
</script>

<style scoped></style>
