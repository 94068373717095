<template>
  <div
    class="fixed z-20 flex h-10 w-full flex-grow justify-between border-b bg-white md:border-b-0"
  >
    <div
      class="absolute hidden w-full border-b md:block"
      style="top: 100%; left: 56.25px"
    />
    <div class="flex pl-5 pt-2 pb-1">
      <router-link :to="'/vetpanel'" :exact="true" class="remove-router-style">
        <img
          class="w-35 h-6"
          :src="require('@/assets/svg/firstvet_logo.svg')"
        />
      </router-link>
    </div>
    <nav class="mr-5 flex flex-row">
      <admin-menu v-show="isAdmin" />
      <div
        v-for="(componentHash, index) of topNavDefinitions"
        :key="index"
        class="flex-col"
        @click="setActiveClass(componentHash.routerLinkActive, index)"
      >
        <nav-button
          v-show="getValueFromDefinitionKey(componentHash, 'isDisplayed')"
          v-tooltip.right="{ content: getToolTip(componentHash) }"
          color="dark"
          :class="['py-2']"
          :exact="componentHash.isExact"
          :to="componentHash.toSlug ? { name: componentHash.toSlug } : null"
          @click="callClickFunction(componentHash)"
        >
          <fv-icon
            v-if="componentHash.icon"
            :icon="componentHash.icon"
            size="md"
          />
          <span
            v-if="isMessageNotificationDisplayed(componentHash)"
            class="-mt-1 h-3 w-3 animate-pulse rounded-full bg-primary"
          />
          <div v-if="componentHash.isPingVisible" class="relative">
            <span
              class="absolute right-0 top-0 h-2 w-2 animate-ping rounded-full bg-primary"
            />
          </div>
        </nav-button>
      </div>
      <ProfileMenu />
    </nav>
  </div>
</template>

<script>
import topNavDefinitions from '@/config/top-navbar-button';
import { FvIcon } from '@/UI/icons';
import { UK_COUNTRY_ID, US_COUNTRY_ID } from '@/config/countries';
import {
  getValueFromDefinitionKey,
  callClickFunction,
} from '@/config/definitionUtils';
import { authComputed, authMethods } from '@/store/auth-helpers';
import NavButton from '@/UI/buttons/NavButton.vue';
import AdminMenu from '@/components/interface/AdminMenu';
import ProfileMenu from '@/components/interface/ProfileMenu.vue';
import { mapGetters, mapMutations, mapState } from 'vuex';

export default {
  components: {
    AdminMenu,
    ProfileMenu,
    NavButton,
    FvIcon,
  },
  data() {
    return {
      topNavDefinitions,
      activeClass: '',
      selectedIndex: null,
    };
  },
  computed: {
    ...authComputed,
    ...mapState('support', ['hasNewSupportMessages']),
    ...mapGetters('auth', ['isAdmin', 'isVet']),
    ...mapState('user', ['user']),

    isMedicalQuestionsDisplayed() {
      return this.$can('manage', 'Questions');
    },
    isChatDisplayed() {
      return (
        this.user.country_id === UK_COUNTRY_ID ||
        this.user.country_id === US_COUNTRY_ID
      );
    },
    displayAdminMenu() {
      return this.isAdmin;
    },
  },
  methods: {
    ...mapMutations(['toggleTimeline', 'toggleSupportTicketModal']),

    setActiveClass(item, index) {
      this.activeClass = item && this.$router.currentRoute.path.includes(item);
      this.selectedIndex = index;
    },
    callClickFunction(...args) {
      return callClickFunction.call(this, ...args);
    },
    getValueFromDefinitionKey(...args) {
      const componentHash = args[0];
      const propertyKey = args[1];
      const isShowTimeLineButton = componentHash.onClick === 'toggleTimeline';
      const isToolTipOrFontIcon =
        propertyKey === 'tooltip' || propertyKey === 'icon';

      if (isShowTimeLineButton && isToolTipOrFontIcon) {
        const caretLeft = 1;
        const caretRight = 0;
        const iconOptions = componentHash[propertyKey].split('/');
        return this.showTimeline
          ? iconOptions[caretRight]
          : iconOptions[caretLeft];
      }

      return getValueFromDefinitionKey.call(this, ...args);
    },

    isMessageNotificationDisplayed(componentHash) {
      const isMessageButton = componentHash.toSlug === 'messages';
      return (
        isMessageButton &&
        this.hasNewSupportMessages &&
        this.$route.name !== 'messages'
      );
    },

    showOrHideSupportTicket() {
      const showModalFlag = true;
      this.toggleSupportTicketModal(showModalFlag);
    },

    getFontAwesomeIconName(componentHash) {
      return this.getValueFromDefinitionKey(componentHash, 'fontIcon');
    },

    getToolTip(componentHash) {
      return this.getValueFromDefinitionKey(componentHash, 'tooltip');
    },
    ...authMethods,
  },
};
</script>

<style scoped>
a.remove-router-style,
a.remove-router-style {
  background-color: rgb(255, 255, 255);
}
.router-link-exact-active {
  background-color: rgb(246, 236, 223);
}
.router-link-exact-active svg {
  color: black;
}
</style>
