var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.user
    ? _c(
        "div",
        { staticClass: "flex h-screen flex-col", attrs: { id: "vetPanel" } },
        [
          _c(
            "div",
            { staticClass: "flex w-full grow bg-brown" },
            [_c("TopNavbar")],
            1
          ),
          _c("div", { staticClass: "flex h-screen flex-row" }, [
            _vm.$can("manage", "Veterinarian") || _vm.$can("manage", "All")
              ? _c(
                  "div",
                  {
                    staticClass:
                      "hidden flex-row bg-white bg-opacity-75 md:flex",
                  },
                  [_c("LeftNavbar")],
                  1
                )
              : _vm._e(),
            _vm.$can("manage", "Veterinarian")
              ? _c(
                  "div",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showTimeline,
                        expression: "showTimeline",
                      },
                    ],
                    staticClass: "z-50 mt-10 hidden w-32 bg-white md:flex",
                    attrs: { "data-testid": "navbar-timeline" },
                  },
                  [_c("Timeline")],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "w-full flex-1 justify-center overflow-auto" },
              [
                _c("router-view", {
                  key: _vm.$route.fullPath,
                  staticClass: "overflow-auto",
                }),
              ],
              1
            ),
          ]),
          _c("service-notification-modal", {
            attrs: {
              data: _vm.remoteConfigs["service_information_message"],
              show: _vm.showServiceModal,
              dismissible:
                _vm.remoteConfigs["service_information_message"] &&
                _vm.remoteConfigs["service_information_message"].dismissible,
            },
            on: {
              close: function ($event) {
                _vm.showServiceModal = false
              },
            },
          }),
          _vm.showSupportTicket && _vm.$can("manage", "Veterinarian")
            ? _c("SupportTicketModal", {
                attrs: {
                  id: _vm.$router.currentRoute.params.id || "",
                  show: _vm.showSupportTicket,
                },
                on: {
                  close: function ($event) {
                    return _vm.toggleSupportTicketModal(false)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }