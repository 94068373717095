var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "nav",
    {
      staticClass:
        "absolute top-0 z-50 mt-10 w-full rounded-lg border-t border-gray-200 bg-white py-3 shadow",
      staticStyle: { "min-width": "412px" },
    },
    [
      _c(
        "ul",
        { staticClass: "flex flex-col" },
        _vm._l(_vm.navDefinitions, function (navButtonDef, index) {
          return _c(
            _vm.getComponentType(navButtonDef),
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.getValueFromDefinitionKey(
                    navButtonDef,
                    "isDisplayed"
                  ),
                  expression:
                    "getValueFromDefinitionKey(navButtonDef, 'isDisplayed')",
                },
              ],
              key: index,
              tag: "component",
              class: navButtonDef.htmlClasses,
              attrs: {
                "exact-active-class": "admin-menu-item-active",
                to: navButtonDef.toSlug ? { name: navButtonDef.toSlug } : "",
                "data-testid": navButtonDef.toSlug,
              },
              on: {
                click: function ($event) {
                  return _vm.callClickFunction(navButtonDef)
                },
              },
            },
            [
              navButtonDef.icon
                ? _c("fv-icon", { attrs: { icon: navButtonDef.icon } })
                : _vm._e(),
              _c("span", { staticClass: "ml-2" }, [
                _vm._v(_vm._s(navButtonDef.linkText)),
              ]),
              navButtonDef.isNew
                ? _c(
                    "span",
                    {
                      staticClass:
                        "ml-2 rounded-lg bg-azure-25 p-1 text-xs uppercase text-navy no-underline shadow",
                    },
                    [_vm._v("New")]
                  )
                : _vm._e(),
              navButtonDef.isUpdated
                ? _c(
                    "span",
                    {
                      staticClass:
                        "ml-2 rounded-lg bg-azure-25 p-1 text-xs uppercase text-navy no-underline shadow",
                    },
                    [_vm._v("Updated")]
                  )
                : _vm._e(),
            ],
            1
          )
        }),
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }