var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "absolute top-2 -right-10 z-20 mt-10 mr-10 mb-4 w-max min-w-full rounded-2xl bg-white shadow-md",
    },
    [
      _c(
        "div",
        {
          staticClass:
            "flex cursor-pointer flex-row rounded-t-2xl border-b pt-1 pb-1 hover:bg-beigeLighter",
          attrs: { "data-testid": "manage-account" },
          on: { click: _vm.goToAccountPage },
        },
        [
          _c(
            "div",
            { staticClass: "m-1 flex flex-col" },
            [
              _c("user-avatar", {
                staticClass: "ml-2 flex-shrink-0 border",
                attrs: { size: 4, avatar: _vm.user.avatarUrl },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "m-auto ml-2 mr-10 flex flex-col" }, [
            _c("div", { staticClass: "-mb-1 text-base text-gray-500" }, [
              _vm._v(_vm._s(_vm.user.display_name)),
            ]),
            _c("p", { staticClass: "text-sm text-gray-500" }, [
              _vm._v("Manage your account"),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "rounded-b-2xl p-1 hover:bg-beigeLighter" },
        [
          _c(
            "nav-button",
            {
              staticClass: "w-full py-3 text-gray-500",
              staticStyle: { "justify-content": "start !important" },
              attrs: {
                color: "dark",
                exact: false,
                to: null,
                "data-testid": "log-out-button",
              },
              on: { click: _vm.logoutUser },
            },
            [
              _c("fv-icon", {
                staticClass: "h-9 w-9 text-gray-500",
                attrs: { icon: "log-out" },
              }),
              _c(
                "div",
                {
                  staticClass:
                    "ml-2 self-center text-base font-semibold text-gray-500",
                },
                [_vm._v(" Sign out ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }