var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "inline-flex items-center" }, [
      _c("div", { staticClass: "relative" }, [
        _c(
          "button",
          {
            staticClass:
              "inline-flex items-center py-2 px-3 text-gray-900 hover:text-action",
            class: _vm.digitalClinicId
              ? "cursor-default"
              : "focus:outline-none ",
            attrs: { disabled: !!_vm.digitalClinicId },
            on: {
              click: function ($event) {
                _vm.openMenu = !_vm.openMenu
              },
            },
          },
          [
            _vm.country
              ? _c(
                  "span",
                  { staticClass: "mr-1 inline-flex select-none items-center" },
                  [
                    _c("span", { staticClass: "hidden text-sm md:block" }, [
                      _vm._v(_vm._s(_vm.country.name)),
                    ]),
                    _c("span", { staticClass: "ml-1" }, [
                      _c("img", {
                        staticClass: "h-5 w-7",
                        attrs: {
                          src: require("@/assets/svg/flags/" +
                            _vm.country.localization.toLowerCase() +
                            ".svg"),
                        },
                      }),
                    ]),
                  ]
                )
              : _vm._e(),
            !_vm.country
              ? _c(
                  "span",
                  [_c("base-spinner", { staticClass: "mx-auto text-sm" })],
                  1
                )
              : _vm._e(),
            !_vm.digitalClinicId
              ? _c("fv-icon", {
                  class: ["svg-fill h-3 w-3"],
                  attrs: { icon: "small-chevron-down" },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.openMenu
          ? _c(
              "nav",
              {
                staticClass:
                  "absolute top-0 right-0 z-50 mt-10 flex w-48 flex-col rounded bg-white py-2 text-sm shadow",
              },
              _vm._l(_vm.countries, function (country) {
                return _c(
                  "button",
                  {
                    key: country.id,
                    staticClass:
                      "py-3 px-4 text-left font-semibold text-gray-800 hover:bg-gray-100",
                    class: { "bg-gray-200 ": country.id === _vm.countryId },
                    on: {
                      click: function ($event) {
                        return _vm.selectCountry(country.id)
                      },
                    },
                  },
                  [_vm._v(" " + _vm._s(country.name) + " ")]
                )
              }),
              0
            )
          : _vm._e(),
      ]),
    ]),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.openMenu,
          expression: "openMenu",
        },
      ],
      staticClass:
        "bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out",
      on: {
        click: function ($event) {
          _vm.openMenu = false
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }