<template>
  <div
    class="absolute top-2 -right-10 z-20 mt-10 mr-10 mb-4 w-max min-w-full rounded-2xl bg-white shadow-md"
  >
    <!-- profile -->
    <div
      class="flex cursor-pointer flex-row rounded-t-2xl border-b pt-1 pb-1 hover:bg-beigeLighter"
      data-testid="manage-account"
      @click="goToAccountPage"
    >
      <div class="m-1 flex flex-col">
        <user-avatar
          :size="4"
          class="ml-2 flex-shrink-0 border"
          :avatar="user.avatarUrl"
        />
      </div>
      <div class="m-auto ml-2 mr-10 flex flex-col">
        <div class="-mb-1 text-base text-gray-500">{{ user.display_name }}</div>
        <p class="text-sm text-gray-500">Manage your account</p>
      </div>
    </div>

    <!-- sign out -->
    <div class="rounded-b-2xl p-1 hover:bg-beigeLighter">
      <nav-button
        color="dark"
        class="w-full py-3 text-gray-500"
        style="justify-content: start !important"
        :exact="false"
        :to="null"
        data-testid="log-out-button"
        @click="logoutUser"
      >
        <fv-icon icon="log-out" class="h-9 w-9 text-gray-500" />
        <div class="ml-2 self-center text-base font-semibold text-gray-500">
          Sign out
        </div>
      </nav-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import UserAvatar from '@/components/user/UserAvatar';
import FvIcon from '@/UI/icons/FvIcon';
import NavButton from '@/UI/buttons/NavButton.vue';
import { authMethods } from '@/store/auth-helpers';

export default {
  components: {
    FvIcon,
    NavButton,
    UserAvatar,
  },
  computed: {
    ...mapState('user', ['user']),
  },
  methods: {
    ...authMethods,
    goToAccountPage() {
      const routePath = this.isAdmin
        ? `/vetpanel/admin/veterinarian/${this.user.id}`
        : `/vetpanel/account/`;
      this.$emit('close-profile-menu', this.variable);
      if (routePath != this.$route.path) {
        this.$router.push(routePath);
      }
    },
    logoutUser() {
      this.logout()
        .then(() => {
          setTimeout(() => {
            this.$router.push('/');
            this.$notify({
              group: 'auth',
              title: 'Signed out',
            });
          }, 500);
        })
        .catch(e => {
          console.error(e);
        });
    },
  },
};
</script>

<style scoped></style>
