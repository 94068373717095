var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    {
      attrs: { name: "fade", mode: "out-in" },
      on: {
        beforeLeave: _vm.beforeLeave,
        enter: _vm.enter,
        afterEnter: _vm.afterEnter,
      },
    },
    [
      _vm.show
        ? _c("div", { staticClass: "fixed inset-0 flex" }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "click-outside",
                    rawName: "v-click-outside",
                    value: _vm.dismissible && _vm.close,
                    expression: "dismissible && close",
                  },
                ],
                staticClass:
                  "min-w-fit relative mr-10 mb-10 ml-auto mt-auto flex flex-col rounded border bg-white p-4 shadow-md",
              },
              [
                _c("div", { staticClass: "min-h-full" }, [
                  _vm.data
                    ? _c("div", [
                        _c("div", { staticClass: "mb-3 text-lg" }, [
                          _vm._v(" " + _vm._s(_vm.data.title) + " "),
                        ]),
                        _c("p", [_vm._v(_vm._s(_vm.data.message))]),
                      ])
                    : _vm._e(),
                ]),
                _vm._t("buttons", function () {
                  return [
                    _vm.dismissible
                      ? _c(
                          "base-button",
                          {
                            staticClass: "mt-5",
                            attrs: { color: "cancel" },
                            on: {
                              click: function ($event) {
                                return _vm.$emit("close")
                              },
                            },
                          },
                          [_vm._v(" Close ")]
                        )
                      : _vm._e(),
                  ]
                }),
              ],
              2
            ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }