<template>
  <transition
    name="fade"
    mode="out-in"
    @beforeLeave="beforeLeave"
    @enter="enter"
    @afterEnter="afterEnter"
  >
    <div v-if="show" class="fixed inset-0 flex">
      <div
        v-click-outside="dismissible && close"
        class="min-w-fit relative mr-10 mb-10 ml-auto mt-auto flex flex-col rounded border bg-white p-4 shadow-md"
      >
        <div class="min-h-full">
          <div v-if="data">
            <div class="mb-3 text-lg">
              {{ data.title }}
            </div>
            <p>{{ data.message }}</p>
          </div>
        </div>

        <slot name="buttons" class="max-w-fit">
          <base-button
            v-if="dismissible"
            color="cancel"
            class="mt-5"
            @click="$emit('close')"
          >
            Close
          </base-button>
        </slot>
      </div>
    </div>
  </transition>
</template>

<script>
import { fadeTransitions } from '@/mixins/index';

export default {
  mixins: [fadeTransitions],

  props: {
    data: {
      type: Object,
      default: undefined,
    },
    show: {
      type: Boolean,
      default: true,
    },
    dismissible: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss"></style>
