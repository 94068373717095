var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "flex h-full flex-col" }, [
    _c(
      "div",
      {
        staticClass:
          "z-20 flex-col items-center border border-l-0 border-fv-gray-border bg-white bg-opacity-75 text-xs shadow-md",
      },
      [
        _c(
          "button",
          {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip.right",
                value: { content: _vm.toolTipContent },
                expression: "{ content: toolTipContent }",
                modifiers: { right: true },
              },
            ],
            staticClass:
              "focus:outline-none flex w-full items-center py-3 px-3",
            class: _vm.nextBooking
              ? "cursor-pointer hover:bg-gray-100"
              : "cursor-default",
            staticStyle: { height: "38px" },
            attrs: { disabled: !_vm.nextBooking },
            on: { click: _vm.goToAppointment },
          },
          [
            _c(
              "p",
              { staticClass: "inline-flex items-center space-x-3" },
              [
                _c("fv-icon", {
                  class:
                    _vm.nextBooking !== undefined
                      ? "text-primary"
                      : "text-gray-500",
                  attrs: {
                    icon:
                      _vm.nextBooking !== undefined
                        ? "chevron-right"
                        : "calendar",
                  },
                }),
                _c("span", [_vm._v(_vm._s(_vm.formattedDate))]),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "w-full flex-1 overflow-auto border-r border-fv-gray-border bg-transparent",
        attrs: { id: "timelineCalendar" },
      },
      [
        _c(
          "div",
          {
            staticClass: "relative",
            on: {
              mouseenter: _vm.mouseenterTimelineHandler,
              mouseleave: _vm.mouseleaveTimelineHandler,
            },
          },
          [
            _c("full-calendar", {
              ref: "timeline",
              attrs: { id: "timeline", options: _vm.calendarOptions },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.showTooltip
      ? _c(
          "div",
          {
            ref: "tooltip",
            staticClass: "tooltip-event arrow-left shadow",
            style: {
              top: _vm.tooltipJsEvent.clientY - _vm.tooltipHeight / 2 + "px",
              left: _vm.tooltipJsEvent.clientX + 20 + "px",
            },
          },
          [
            _c("p", { staticClass: "mb-2" }, [
              _c("strong", [
                _vm.tooltipProps.animalName
                  ? _c("span", [_vm._v(_vm._s(_vm.tooltipProps.animalName))])
                  : _c("i", [_vm._v("No animal name")]),
                _vm.tooltipProps.animalType
                  ? _c("span", [
                      _vm._v(" (" + _vm._s(_vm.tooltipProps.animalType) + ")"),
                    ])
                  : _c("i", [_vm._v(" (No animal type)")]),
              ]),
            ]),
            _c("p", { staticClass: "mb-1" }, [
              _vm.tooltipProps.user
                ? _c("span", [_c("i", [_vm._v(_vm._s(_vm.tooltipProps.user))])])
                : _c("i", [_vm._v("No username")]),
            ]),
            _c("p", [
              _vm.tooltipProps.description
                ? _c("span", [
                    _vm._v(" " + _vm._s(_vm.tooltipProps.description)),
                  ])
                : _c("i", [_vm._v("No description")]),
            ]),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }