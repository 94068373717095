var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "py-1" }, [
    _c("div", { staticClass: "inline-flex items-center" }, [
      _c("div", { staticClass: "inline-flex space-x-2" }, [
        _c(
          "div",
          { staticClass: "relative" },
          [
            _c(
              "button",
              {
                staticClass:
                  "focus:outline-none inline-flex items-center rounded py-2 px-2 text-gray-900 hover:text-action",
                attrs: { "data-testid": "admin-menu" },
                on: {
                  click: function ($event) {
                    _vm.menuIsOpen = !_vm.menuIsOpen
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "inline-flex items-center space-x-1" },
                  [
                    _c("span", { staticClass: "text-sm" }, [_vm._v("Menu")]),
                    _c("fv-icon", {
                      class: ["svg-fill h-3 w-3"],
                      attrs: { icon: "small-chevron-down" },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c("admin-navbar", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.menuIsOpen,
                  expression: "menuIsOpen",
                },
              ],
              on: { close: _vm.closeAdminMenu },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c(
      "div",
      { staticClass: "inline-flex items-center" },
      [_c("country-selector", { staticClass: "ml-2" })],
      1
    ),
    _c("div", {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.menuIsOpen,
          expression: "menuIsOpen",
        },
      ],
      staticClass:
        "bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out",
      on: {
        click: function ($event) {
          _vm.menuIsOpen = false
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }