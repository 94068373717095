<template>
  <div class="flex h-full flex-col">
    <div
      class="z-20 flex-col items-center border border-l-0 border-fv-gray-border bg-white bg-opacity-75 text-xs shadow-md"
    >
      <button
        v-tooltip.right="{ content: toolTipContent }"
        class="focus:outline-none flex w-full items-center py-3 px-3"
        :class="
          nextBooking ? 'cursor-pointer hover:bg-gray-100' : 'cursor-default'
        "
        :disabled="!nextBooking"
        style="height: 38px"
        @click="goToAppointment"
      >
        <p class="inline-flex items-center space-x-3">
          <fv-icon
            :icon="nextBooking !== undefined ? 'chevron-right' : 'calendar'"
            :class="
              nextBooking !== undefined ? 'text-primary' : 'text-gray-500'
            "
          />
          <span>{{ formattedDate }}</span>
        </p>
      </button>
    </div>
    <div
      id="timelineCalendar"
      class="w-full flex-1 overflow-auto border-r border-fv-gray-border bg-transparent"
    >
      <div
        class="relative"
        @mouseenter="mouseenterTimelineHandler"
        @mouseleave="mouseleaveTimelineHandler"
      >
        <full-calendar
          id="timeline"
          ref="timeline"
          :options="calendarOptions"
        />
      </div>
    </div>
    <div
      v-if="showTooltip"
      ref="tooltip"
      class="tooltip-event arrow-left shadow"
      :style="{
        top: tooltipJsEvent.clientY - tooltipHeight / 2 + 'px',
        left: tooltipJsEvent.clientX + 20 + 'px',
      }"
    >
      <p class="mb-2">
        <strong>
          <span v-if="tooltipProps.animalName">{{
            tooltipProps.animalName
          }}</span>
          <i v-else>No animal name</i>
          <span v-if="tooltipProps.animalType">
            ({{ tooltipProps.animalType }})</span
          >
          <i v-else> (No animal type)</i>
        </strong>
      </p>
      <p class="mb-1">
        <span v-if="tooltipProps.user"
          ><i>{{ tooltipProps.user }}</i></span
        >
        <i v-else>No username</i>
      </p>
      <p>
        <span v-if="tooltipProps.description">
          {{ tooltipProps.description }}</span
        >
        <i v-else>No description</i>
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  mapShiftsToTimeline,
  mapBookingsToTimeline,
  isSlotBooked,
} from '@/utils/schedule-utils';
import timelineConfig from '@/config/timeline-schedule';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { ScheduleApi } from '@/api';
import {
  format,
  subDays,
  addDays,
  addMinutes,
  subMinutes,
  isAfter,
  formatRelative,
} from 'date-fns';

export default {
  components: {
    FullCalendar,
  },
  data() {
    return {
      currentTime: null,
      currentDate: null,
      shouldScroll: true,
      scrollInterval: null,
      interval: null,
      nextBooking: null,
      showTooltip: false,
      tooltipProps: null,
      tooltipJsEvent: null,
      tooltipHeight: 1,
      calendarOptions: {
        plugins: [
          dayGridPlugin,
          timeGridPlugin,
          interactionPlugin, // needed for dateClick
          resourceTimelinePlugin,
        ],

        eventSources: [
          /** Fetch shift */
          {
            events: async (info, successCallback) => {
              const from = format(
                subDays(new Date(info.start), 1),
                'yyyy-MM-dd'
              ); // Add and subtract 1 day to support shifts..
              const to = format(addDays(new Date(info.end), 1), 'yyyy-MM-dd'); // ..overlapping midnight

              const shifts = await this.fetchShifts(from, to);
              const mappedShifts = mapShiftsToTimeline(shifts);
              return successCallback(mappedShifts);
            },
            display: 'background',
          },

          /** Fetch bookings */
          {
            events: async (info, successCallback) => {
              const from = format(
                subDays(new Date(info.start), 1),
                'yyyy-MM-dd'
              );
              const to = format(addDays(new Date(info.end), 1), 'yyyy-MM-dd');

              const bookings = await this.fetchBookings(from, to);
              const mappedBookings = mapBookingsToTimeline(bookings);

              return successCallback(mappedBookings);
            },
            id: 'bookings',
          },
        ],
        eventClick: calEvent => {
          const event = calEvent.event._def.extendedProps;
          this.refetchEvents();
          this.$router
            .push({
              name: 'appointment',
              params: { id: event.appointmentID },
            })
            .catch(() => {});
        },
        eventMouseEnter: calEvent => {
          // If statement to only trigger mouseover effect on bookings and not when hovering over background availability event
          if (Object.entries(calEvent.event.extendedProps).length > 0) {
            this.showTooltip = true;
            this.tooltipProps = calEvent.event.extendedProps;
            this.tooltipJsEvent = calEvent.jsEvent;

            setTimeout(() => {
              if (this.$refs.tooltip) {
                this.tooltipHeight = this.$refs.tooltip.clientHeight;
              }
            }, 100);
          }
        },
        eventMouseLeave: () => {
          this.showTooltip = false;
        },
        ...timelineConfig,
      },
    };
  },
  computed: {
    ...mapGetters({ locale: 'getLocale' }),
    ...mapState('user', ['user']),

    formattedDate() {
      if (this.nextBooking) {
        const relativeDate = formatRelative(
          new Date(this.nextBooking.dateTime),
          new Date()
        );
        return relativeDate.charAt(0).toUpperCase() + relativeDate.slice(1);
      }
      return this.nextBooking === undefined ? 'No activities ' : 'Loading ...';
    },
    toolTipContent() {
      if (this.nextBooking) {
        return this.createTooltip(this.nextBooking);
      }
      return '';
    },
  },
  mounted() {
    this.calendar = this.$refs.timeline.getApi();
    this.startTimelineIntervalOnTheMinute();
    this.scrollInterval = setInterval(this.scrollToBottom, 6000);
    this.scrollToBottom();
    this.currentDate = new Date();
  },

  beforeDestroy() {
    this.clearInterval(this.interval);
    this.clearInterval(this.scrollInterval);
  },

  methods: {
    async fetchShifts(fromDate, toDate) {
      const { data } = await ScheduleApi.fetchSchedule({
        fromDate,
        toDate,
        countryId: this.user.country_id,
        vetId: this.user.id,
      });

      return data;
    },

    async fetchBookings(fromDate, toDate) {
      const { data } = await ScheduleApi.fetchBookedSlots({
        fromDate,
        toDate,
        countryId: this.user.country_id,
        vetId: this.user.id,
      });

      // Find next booking to show in "upcoming" box
      const now = new Date();
      this.nextBooking = data.find(booking => {
        return (
          isAfter(new Date(booking.dateTime), now) && isSlotBooked(booking)
        );
      });

      return data;
    },

    startTimelineIntervalOnTheMinute() {
      setTimeout(() => {
        this.interval = setInterval(this.refetchEvents, 60000);
        this.refetchEvents();
      }, (60 - new Date().getSeconds()) * 1000);
    },

    ifNextDaySwitchTimelineToNextDay() {
      if (format(new Date(this.currentDate), 'd') === new Date().getDate()) {
        this.currentDate = new Date();
        this.calendar.next();
      }
    },

    refetchEvents() {
      if (this.$refs.timeline) {
        this.currentTime = this.setCurrentTime();
        this.ifNextDaySwitchTimelineToNextDay();
        this.calendar.refetchEvents();
      }
    },

    setCurrentTime() {
      // Get the current time and round it to closest half hour
      const start = new Date();
      const remainder = 30 - (start.getMinutes() % 30);
      const currentTime =
        remainder >= 30
          ? format(addMinutes(new Date(start), remainder), 'HH:mm' + ':00')
          : format(
              subMinutes(new Date(start), 30 - remainder),
              'HH:mm' + ':00'
            );
      return currentTime;
    },
    scrollToBottom() {
      if (this.$refs.timeline) {
        // If we don't have current time, set it (is set in the refetch events method every 60 sec)
        if (!this.currentTime) {
          this.currentTime = this.setCurrentTime();
        }

        // Get the correct element in the view to scroll to
        document
          .querySelectorAll(`[data-time="${this.currentTime}"]`)[0]
          .scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
      }
    },
    mouseenterTimelineHandler() {
      clearInterval(this.scrollInterval);
    },
    mouseleaveTimelineHandler() {
      this.scrollInterval = setInterval(this.scrollToBottom, 6000);
    },
    createTooltip(event) {
      if (!event) return '';
      return `<div class="text-base">
            <p class="mb-2"><strong>
            ${event.animalName || '<i>No animal name</i>'}
            (${event.animalType || '<i>No animal type</i>'})
            </strong></p>
            <p class="mb-1"><i>
            ${event.customerName || '<i>No user name</i>'}</i></p>
            <p>${event.description || '<i>No description</i>'}</p>
            </div>`;
    },
    goToAppointment() {
      this.$router
        .push({
          name: 'appointment',
          params: { id: this.nextBooking.appointment_id },
        })
        .catch(err => {
          console.error(err);
        });
    },

    clearInterval(interval) {
      if (!interval) {
        return;
      }
      clearInterval(interval);
      interval = null;
    },
  },
};
</script>

<style lang="scss">
#timelineCalendar {
  .fc-timegrid-now-indicator-arrow,
  .fc-timegrid-now-indicator-line {
    border-top-width: 2px;
  }
  .fc-timegrid-now-indicator-arrow {
    border-left-width: 6px;
    border-right-width: 0px;
    border-top-color: rgba(0, 0, 0, 0);
    border-top-width: 5px;
  }
  .fc td,
  .fc th {
    border-style: solid;
    border-width: 0px;
    border-top-width: 0.1px;
    border-color: #e6e7eb;
  }

  #timeline > div.fc-view-harness > div > table > tbody > tr > td {
    &:first-child {
      border-top-width: 0;
    }
  }

  #timeline {
    .fc-scrollgrid {
      border-top-width: 0px;
      border-left-width: 0px;
    }
    .fc-col-header {
      display: none !important;
    }
    .fc-header-toolbar {
      display: none !important;
    }
    .fc-view-harness {
      .fc-content {
        display: flex;
        align-items: center;
      }
      .fc-event-main {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 600;
        cursor: pointer;
      }
      .fc-event-title {
        white-space: nowrap;
      }
      .fc-event-main-frame {
        display: flex;
        align-items: center;
      }
      .fc-title {
        font-size: 12px;
        font-weight: 500;
      }
      .fc-timegrid-slot-label {
        font-weight: 700;
        font-size: 12px;
        background: #fefaf4;
        color: #101c4e;
        border-right: 1px solid #f8ecde;
      }
      .fc-timegrid-event {
        box-shadow: none;
      }
    }

    .fc-day-today {
      background: white;
    }
  }
}
.tooltip-event {
  width: 200px;
  height: auto;
  position: absolute;
  z-index: 10001;
  padding: 0.8rem;
  border: #f1eeee;
  border-radius: 2px;
  left: 175px; // Position previous calculated by jquery mouse click position.
}
.arrow-left {
  background: #eeeeee;
}
.arrow-left:before,
.arrow-left:after {
  content: '';
  position: absolute;
  right: 100%;
  top: 50%;
  width: 0;
  height: 0;
}
.arrow-left:after {
  border-width: 6px;
  border-style: solid;
  border-color: transparent #eeeeee transparent transparent;
  margin-top: -6px;
}
</style>
