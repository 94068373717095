var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [
        "relative flex flex-col ",
        _vm.isProfileMenuExtended ? "router-link-exact-active" : "",
      ],
    },
    [
      _c(
        "div",
        {
          staticClass: "flex cursor-pointer p-3 py-1 pr-4 hover:text-action",
          attrs: { "data-testid": "dropdown-menu" },
          on: { click: _vm.toggleDropdownProfileMenu },
        },
        [
          _c("user-avatar", {
            staticClass: "mx-auto flex-shrink-0 border",
            attrs: { size: 2, avatar: _vm.user.avatarUrl },
          }),
          _c(
            "div",
            { staticClass: "absolute top-5 right-0" },
            [
              _c("fv-icon", {
                class: [
                  "svg-fill h-3 w-3 ",
                  _vm.isProfileMenuExtended ? "text-blue-700" : "",
                ],
                attrs: { icon: "small-chevron-down" },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("ProfileDropdown", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isProfileMenuExtended,
            expression: "isProfileMenuExtended",
          },
        ],
        on: { "close-profile-menu": _vm.toggleDropdownProfileMenu },
      }),
      _c("div", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.isProfileMenuExtended,
            expression: "isProfileMenuExtended",
          },
        ],
        staticClass:
          "bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out",
        on: {
          click: function ($event) {
            _vm.isProfileMenuExtended = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }