/**
 * This file provides various functions used for parsing through component definitions.
 *
 * For example of component definitions, see ./left-navbar-buttons.ts and ./admin-nav-buttons.ts.
 *
 * These definition files contain arrays of object hashes that can be iterated through and then rendered in
 * a parent component (for examples, see Leftnavbar.vue or AdminNavbar.vue).
 *
 * Within the definition files, we often want to reference functions supplied by the parent component (to find out
 * dynamically if something should be displayed or not, for example). These functions provide the ability to do that
 * amoung other thigs and are commented in more detail below.
 */

/**
		componentHash is an object hash that contains the definition for one component.

		propertyKey refers to one property within that componentHash.

		The property maybe a string the correlates to either a 
		computed property or function in this component, or may alternatively be
		hard-coded as a boolean in the definition file.

		If the nav button definition contains a boolean, then we simply return
		that boolean.

		If the nav button contains a valid function name or a valid computed property, 
		then we return the result of that function call or computed property.

		this is used because somethings (e.g. whether to show a message notification)
		depend on computed properties, while others are either always true or false
		depending on the particular nav button definition (e.g. isDisplayed for the
		Calendar is always true, we don't need a computed property for that).
		
		@params [Object] componentHash
		@params [String] propertyKey - The key in the tabs definition
		@returns any
	*/
function getValueFromDefinitionKey(componentHash, propertyKey) {
  const property = componentHash[propertyKey];
  const isPredefinedBoolean = typeof property === 'boolean';

  if (isPredefinedBoolean) {
    return property;
  }

  const isPropertyDefinedOnthis = this[property] !== undefined;
  const isPropertyAFunction = typeof property === 'function';
  const isPropertyAComputedVariable =
    isPropertyDefinedOnthis && !isPropertyAFunction;

  const value = (function(vueComponent) {
    if (!isPropertyDefinedOnthis) {
      return property;
    }
    if (isPropertyAComputedVariable) {
      return vueComponent[property];
    }
    if (isPropertyAFunction) {
      return vueComponent[property]();
    }

    return null;
  })(this);

  return value;
}

/*
		Calls the function named in componentHash.onClick, if it is valid. Otherwise
		does nothing.
		@params [Object] componentHash
	*/
function callClickFunction(componentHash) {
  const hasValidFunction =
    componentHash.onClick && this[componentHash.onClick] instanceof Function;

  if (hasValidFunction) {
    this[componentHash.onClick]();
  }
}

/** 
		Returns the font-awesome icon name.

		Required because some definitions have icons that change depending on state.

		@params [Object] componentHash
		@returns [String]
	*/
function getVariableValue(componentHash, key) {
  const isShowTimeLineButton = componentHash.onClick === 'toggleTimeline';

  if (isShowTimeLineButton) {
    const caretLeft = 1;
    const caretRight = 0;
    const iconOptions = componentHash[key].split('/');
    return this.showTimeline ? iconOptions[caretRight] : iconOptions[caretLeft];
  }

  return componentHash[key];
}

export { getVariableValue, callClickFunction, getValueFromDefinitionKey };
