var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "base-modal",
    {
      attrs: { show: _vm.showSupportTicket },
      on: {
        close: function ($event) {
          return _vm.toggleSupportTicketModal(false)
        },
      },
    },
    [
      _vm._t("default", function () {
        return [
          _c(
            "h2",
            {
              staticClass:
                "mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl",
            },
            [
              _c("fv-icon", { attrs: { icon: "lifebuoy", size: "lg" } }),
              _c("span", [_vm._v("Submit a support ticket")]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mb-4 rounded-lg bg-gray-50 p-4 text-gray-600" },
            [
              _c("p", [
                _vm._v(
                  " Use a lifebuoy if you need help with something about a specific consultation, or want to report a technical issue in VetPanel. Depending on what case type you have chosen, someone from Tech, Customer service or VetOps will answer your question if we need more information or need to get back to you. Prioritize your lifebuoy as follows: "
                ),
              ]),
              _c("ul", { staticClass: "my-3 list-disc pl-6" }, [
                _c("li", [
                  _c("span", { staticClass: "font-black" }, [_vm._v("High:")]),
                  _vm._v(
                    " Only use this prio if there is a serious disruption in the service, for example if VetPanel is down and critical functions do not work. "
                  ),
                ]),
                _c("li", [
                  _c("span", { staticClass: "font-black" }, [
                    _vm._v("Medium:"),
                  ]),
                  _vm._v(
                    " Use this prio if you want to report a bug in VetPanel, having questions about billing or change information on customer profiles for journal purposes. "
                  ),
                ]),
                _c("li", [
                  _c("span", { staticClass: "font-black" }, [_vm._v("Low:")]),
                  _vm._v(
                    " Use this prio if you want to report problems in a meeting related to audio, video or customer related questions such as refunds and merge journals. Also use this prio if you have a case that might be reported due to lack of animal welfare, to make us aware of the case. "
                  ),
                ]),
              ]),
              _c("p", [
                _vm._v(
                  " Describe your problem as detailed as possible, and see if you can identify the issue yourself by using our "
                ),
                _c(
                  "a",
                  {
                    staticClass: "text-primary",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        _vm.$router.push({ name: "network-diagnosis" }),
                          _vm.$emit("close")
                      },
                    },
                  },
                  [_vm._v(" network test ")]
                ),
                _vm._v(". "),
              ]),
            ]
          ),
          _c(
            "ValidationObserver",
            { ref: "observer" },
            [
              _c("div", { staticClass: "grid grid-cols-3 gap-5" }, [
                _c(
                  "div",
                  { staticClass: "mb-4 flex flex-wrap" },
                  [
                    _c("base-label", { attrs: { for: "type" } }, [
                      _vm._v(" Who do you need help from? "),
                    ]),
                    _c("v-select", {
                      staticClass: "w-full",
                      attrs: {
                        id: "type",
                        label: "name",
                        options: _vm.typeOptions,
                        clearable: false,
                        reduce: function (type) {
                          return type.id
                        },
                      },
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "mb-4 flex flex-wrap" },
                  [
                    _c("base-label", { attrs: { for: "priority" } }, [
                      _vm._v(" Priority level "),
                    ]),
                    _c("v-select", {
                      staticClass: "w-full",
                      attrs: {
                        id: "priority",
                        label: "name",
                        options: _vm.priorityOptions,
                        clearable: false,
                        reduce: function (priority) {
                          return priority.id
                        },
                      },
                      model: {
                        value: _vm.form.priority,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "priority", $$v)
                        },
                        expression: "form.priority",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "mb- flex w-full flex-wrap" }, [
                  _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("base-label", { attrs: { for: "appointmentId" } }, [
                        _vm._v(" Appointment "),
                      ]),
                      _c(
                        "v-select",
                        {
                          attrs: {
                            options: _vm.filteredAppointments,
                            placeholder: _vm.loadingList
                              ? "Loading ..."
                              : "Choose an appointment",
                            loading: _vm.loadingList,
                            disabled: _vm.loadingList,
                          },
                          model: {
                            value: _vm.selectedAppointment,
                            callback: function ($$v) {
                              _vm.selectedAppointment = $$v
                            },
                            expression: "selectedAppointment",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              attrs: { slot: "no-options" },
                              slot: "no-options",
                            },
                            [
                              !_vm.loadingList
                                ? _c("span", [
                                    _vm._v("No recent appointments found"),
                                  ])
                                : _vm._e(),
                            ]
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("ValidationProvider", {
                attrs: { rules: "required", name: "message" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (ref) {
                      var errors = ref.errors
                      return [
                        _c(
                          "div",
                          { staticClass: "mb-4 mt-2 flex w-full flex-wrap" },
                          [
                            _c(
                              "base-label",
                              { attrs: { for: "message" } },
                              [
                                _vm._v(" How can we help you? "),
                                _c("span", { staticClass: "text-red-600" }, [
                                  _vm._v("*"),
                                ]),
                                _c("transition", { attrs: { name: "fade" } }, [
                                  errors.length
                                    ? _c(
                                        "span",
                                        {
                                          staticClass:
                                            "ml-8 p-2 text-center font-semibold text-red-800",
                                        },
                                        [_vm._v(_vm._s(errors[0]))]
                                      )
                                    : _vm._e(),
                                ]),
                              ],
                              1
                            ),
                            _c("textarea-input", {
                              staticClass: "w-full",
                              attrs: {
                                id: "message",
                                name: "message",
                                placeholder: "Describe your issue",
                              },
                              model: {
                                value: _vm.form.message,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "message", $$v)
                                },
                                expression: "form.message",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c("div", { staticClass: "mb-6 w-full rounded bg-gray-50 py-4" }, [
            _c(
              "button",
              {
                staticClass:
                  "focus:outline-none flex w-full items-center justify-between px-3 hover:opacity-75",
                attrs: { type: "button" },
                on: {
                  click: function ($event) {
                    _vm.showInfo = !_vm.showInfo
                  },
                },
              },
              [
                _c("p", { staticClass: "mr-4 font-semibold" }, [
                  _vm._v("Additional information"),
                ]),
                _c("font-awesome-icon", {
                  attrs: { icon: _vm.showInfo ? "caret-up" : "caret-down" },
                }),
              ],
              1
            ),
            _vm.showInfo
              ? _c(
                  "div",
                  { staticClass: "mt-2 w-full rounded-lg bg-gray-50 p-3" },
                  [
                    _c("div", { staticClass: "mb-2" }, [
                      _c("p", { staticClass: "mb-1 block text-sm" }, [
                        _vm._v("Current url"),
                      ]),
                      _c("p", { staticClass: "block text-xs" }, [
                        _vm._v(" " + _vm._s(_vm.currentUrl) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("p", { staticClass: "mb-1 block text-sm" }, [
                        _vm._v("Os"),
                      ]),
                      _c("p", { staticClass: "block text-xs" }, [
                        _vm._v(" " + _vm._s(_vm.os) + " "),
                      ]),
                    ]),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("p", { staticClass: "mb-1 block text-sm" }, [
                        _vm._v("Browser"),
                      ]),
                      _c("p", { staticClass: "block text-xs" }, [
                        _vm._v(" " + _vm._s(_vm.browser) + " "),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "mb-2" },
                      [
                        _c("p", { staticClass: "mb-1 block text-sm" }, [
                          _vm._v("Audio devices"),
                        ]),
                        _vm._l(_vm.audioDevices, function (device) {
                          return _c(
                            "div",
                            {
                              key: device.deviceId,
                              staticClass: "mb-2 block text-xs",
                            },
                            [
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Device id: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.deviceId)),
                                ]),
                              ]),
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Label: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.label)),
                                ]),
                              ]),
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Kind: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.kind)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                        _c("p", { staticClass: "mb-1 block text-sm" }, [
                          _vm._v("Video devices"),
                        ]),
                        _vm._l(_vm.videoDevices, function (device) {
                          return _c(
                            "div",
                            {
                              key: device.deviceId,
                              staticClass: "mt-1 block text-xs",
                            },
                            [
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Device id: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.deviceId)),
                                ]),
                              ]),
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Label: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.label)),
                                ]),
                              ]),
                              _c("p", { staticClass: "block" }, [
                                _vm._v(" Kind: "),
                                _c("span", { staticClass: "italic" }, [
                                  _vm._v(_vm._s(device.kind)),
                                ]),
                              ]),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _c("div", { staticClass: "mb-2" }, [
                      _c("p", { staticClass: "mb-1 block text-sm" }, [
                        _vm._v("Full details"),
                      ]),
                      _c("p", { staticClass: "block text-xs" }, [
                        _vm._v(" " + _vm._s(_vm.detectRTCResult) + " "),
                      ]),
                    ]),
                  ]
                )
              : _vm._e(),
          ]),
        ]
      }),
      _c(
        "div",
        {
          staticClass: "flex w-full justify-end space-x-2 p-2",
          attrs: { slot: "buttons" },
          slot: "buttons",
        },
        [
          _c(
            "base-button",
            {
              attrs: { color: "cancel" },
              on: {
                click: function ($event) {
                  return _vm.$emit("close", false)
                },
              },
            },
            [_vm._v(" Cancel ")]
          ),
          _c(
            "base-button",
            {
              attrs: {
                loading: _vm.loading,
                color: "primary",
                disabled: _vm.loading,
              },
              on: { click: _vm.submit },
            },
            [_vm._v(" Send ")]
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }