<template>
  <img
    v-if="!imageLoadError && avatar"
    :src="avatar"
    :class="`h-${size * 4} w-${size * 4}`"
    alt="Profile picture"
    class="rounded-full object-cover"
    @error="imageLoadError = true"
  >
  <font-awesome-layers
    v-else
    class="fa-lg"
    :style="{ fontSize: `${size}em` }"
  >
    <font-awesome-icon
      icon="circle"
      color="#b8c2cc"
    />
    <font-awesome-icon
      icon="user-md"
      transform="shrink-6"
      color="#ffffff"
    />
  </font-awesome-layers>
</template>

<script>
import { FontAwesomeLayers } from '@fortawesome/vue-fontawesome';
import { mapState } from 'vuex';

export default {
  components: {
    FontAwesomeLayers,
  },
  props: {
    avatar: {
      type: String,
      default: '',
    },
    size: {
      type: Number,
      default: 7,
    },
  },
  data() {
    return {
      imageLoadError: false,
    };
  },
  computed: {
    ...mapState(['platform']),
  },
};
</script>
