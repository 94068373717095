interface NavButtonDefinition {
  htmlClasses: string | null;
  linkText: string;
  toSlug: string | null;
  isDisplayed: string | boolean;
  isNew: boolean;
  onClick: string | null;
  icon?: string;
  isUpdated?: boolean;
}

const CLASS_STR_BASE = 'py-4 hover:bg-gray-200 px-4 inline-flex items-center';

const navButtonDefs: NavButtonDefinition[] = [
  {
    htmlClasses: CLASS_STR_BASE,
    linkText: '2288 Dashboard',
    toSlug: '2288-dashboard',
    isDisplayed: 'canManageClinicListing',
    isNew: false,
    onClick: 'goto',
    icon: 'dashboard',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Dashboard',
    toSlug: 'schedule-dashboard',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'dashboard',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Schedule',
    toSlug: 'schedule',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'bookings-multiple',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Veterinarians',
    toSlug: 'veterinarians',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'vet-account',
    isUpdated: false,
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Follow ups',
    toSlug: 'book-follow-up-appointment',
    isDisplayed: 'isDev',
    isNew: true,
    onClick: 'goto',
    icon: 'consultations',
    isUpdated: false,
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Animals',
    toSlug: 'animals',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'pet',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Clinics',
    toSlug: 'clinics',
    isDisplayed: 'isClinicsButtonDisplayed',
    isNew: false,
    onClick: 'goto',
    icon: 'clinics',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Clinic map',
    toSlug: 'clinic-map',
    isDisplayed: 'isClinicsButtonDisplayed',
    isNew: false,
    onClick: 'goto',
    icon: 'google-maps',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Support Tickets',
    toSlug: 'support-tickets',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'lifebuoy',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Journal Templates',
    toSlug: 'journal-templates',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'consultations',
  },

  {
    htmlClasses: CLASS_STR_BASE,
    linkText: 'Releases',
    toSlug: 'releases',
    isDisplayed: 'isReleasesButtonDisplayed',
    isNew: false,
    onClick: 'goto',
    icon: 'rocket',
  },

  {
    htmlClasses: `${CLASS_STR_BASE}`,
    linkText: 'Settings',
    toSlug: 'country-settings',
    isDisplayed: 'canManageAll',
    isNew: false,
    onClick: 'goto',
    icon: 'settings-alt',
  },

  {
    htmlClasses: `${CLASS_STR_BASE}`,
    linkText: 'Sign Out',
    toSlug: null,
    isDisplayed: true,
    isNew: false,
    onClick: 'signOut',
    icon: 'log-out',
  },
];

export default navButtonDefs;
