<template>
  <base-modal
    :show="showSupportTicket"
    @close="toggleSupportTicketModal(false)"
  >
    <slot>
      <h2
        class="mb-4 flex w-full items-center space-x-2 border-b pb-2 font-display text-2xl"
      >
        <fv-icon icon="lifebuoy" size="lg" />
        <span>Submit a support ticket</span>
      </h2>
      <div class="mb-4 rounded-lg bg-gray-50 p-4 text-gray-600">
        <p>
          Use a lifebuoy if you need help with something about a specific
          consultation, or want to report a technical issue in VetPanel.
          Depending on what case type you have chosen, someone from Tech,
          Customer service or VetOps will answer your question if we need more
          information or need to get back to you. Prioritize your lifebuoy as
          follows:
        </p>

        <ul class="my-3 list-disc pl-6">
          <li>
            <span class="font-black">High:</span> Only use this prio if there is
            a serious disruption in the service, for example if VetPanel is down
            and critical functions do not work.
          </li>
          <li>
            <span class="font-black">Medium:</span> Use this prio if you want to
            report a bug in VetPanel, having questions about billing or change
            information on customer profiles for journal purposes.
          </li>
          <li>
            <span class="font-black">Low:</span> Use this prio if you want to
            report problems in a meeting related to audio, video or customer
            related questions such as refunds and merge journals. Also use this
            prio if you have a case that might be reported due to lack of animal
            welfare, to make us aware of the case.
          </li>
        </ul>

        <p>
          Describe your problem as detailed as possible, and see if you can
          identify the issue yourself by using our
          <a
            class="text-primary"
            href="#"
            @click.prevent="
              $router.push({ name: 'network-diagnosis' }), $emit('close')
            "
          >
            network test </a
          >.
        </p>
      </div>
      <ValidationObserver ref="observer">
        <div class="grid grid-cols-3 gap-5">
          <div class="mb-4 flex flex-wrap">
            <base-label for="type"> Who do you need help from? </base-label>
            <v-select
              id="type"
              v-model="form.type"
              class="w-full"
              label="name"
              :options="typeOptions"
              :clearable="false"
              :reduce="type => type.id"
            />
          </div>
          <div class="mb-4 flex flex-wrap">
            <base-label for="priority"> Priority level </base-label>
            <v-select
              id="priority"
              v-model="form.priority"
              class="w-full"
              label="name"
              :options="priorityOptions"
              :clearable="false"
              :reduce="priority => priority.id"
            />
          </div>

          <div class="mb- flex w-full flex-wrap">
            <div class="w-full">
              <base-label for="appointmentId"> Appointment </base-label>
              <v-select
                v-model="selectedAppointment"
                :options="filteredAppointments"
                :placeholder="
                  loadingList ? 'Loading ...' : 'Choose an appointment'
                "
                :loading="loadingList"
                :disabled="loadingList"
              >
                <div slot="no-options">
                  <span v-if="!loadingList">No recent appointments found</span>
                </div>
              </v-select>
            </div>
          </div>
        </div>

        <ValidationProvider v-slot="{ errors }" rules="required" name="message">
          <div class="mb-4 mt-2 flex w-full flex-wrap">
            <base-label for="message">
              How can we help you?
              <span class="text-red-600">*</span>
              <transition name="fade">
                <span
                  v-if="errors.length"
                  class="ml-8 p-2 text-center font-semibold text-red-800"
                  >{{ errors[0] }}</span
                >
              </transition>
            </base-label>

            <textarea-input
              id="message"
              v-model="form.message"
              class="w-full"
              name="message"
              placeholder="Describe your issue"
            />
          </div>
        </ValidationProvider>
      </ValidationObserver>

      <div class="mb-6 w-full rounded bg-gray-50 py-4">
        <button
          type="button"
          class="focus:outline-none flex w-full items-center justify-between px-3 hover:opacity-75"
          @click="showInfo = !showInfo"
        >
          <p class="mr-4 font-semibold">Additional information</p>
          <font-awesome-icon :icon="showInfo ? 'caret-up' : 'caret-down'" />
        </button>

        <div v-if="showInfo" class="mt-2 w-full rounded-lg bg-gray-50 p-3">
          <div class="mb-2">
            <p class="mb-1 block text-sm">Current url</p>
            <p class="block text-xs">
              {{ currentUrl }}
            </p>
          </div>
          <div class="mb-2">
            <p class="mb-1 block text-sm">Os</p>
            <p class="block text-xs">
              {{ os }}
            </p>
          </div>
          <div class="mb-2">
            <p class="mb-1 block text-sm">Browser</p>
            <p class="block text-xs">
              {{ browser }}
            </p>
          </div>
          <div class="mb-2">
            <p class="mb-1 block text-sm">Audio devices</p>
            <div
              v-for="device in audioDevices"
              :key="device.deviceId"
              class="mb-2 block text-xs"
            >
              <p class="block">
                Device id: <span class="italic">{{ device.deviceId }}</span>
              </p>
              <p class="block">
                Label: <span class="italic">{{ device.label }}</span>
              </p>
              <p class="block">
                Kind: <span class="italic">{{ device.kind }}</span>
              </p>
            </div>
            <p class="mb-1 block text-sm">Video devices</p>
            <div
              v-for="device in videoDevices"
              :key="device.deviceId"
              class="mt-1 block text-xs"
            >
              <p class="block">
                Device id: <span class="italic">{{ device.deviceId }}</span>
              </p>
              <p class="block">
                Label: <span class="italic">{{ device.label }}</span>
              </p>
              <p class="block">
                Kind: <span class="italic">{{ device.kind }}</span>
              </p>
            </div>
          </div>
          <div class="mb-2">
            <p class="mb-1 block text-sm">Full details</p>
            <p class="block text-xs">
              {{ detectRTCResult }}
            </p>
          </div>
        </div>
      </div>
    </slot>
    <div slot="buttons" class="flex w-full justify-end space-x-2 p-2">
      <base-button color="cancel" @click="$emit('close', false)">
        Cancel
      </base-button>
      <base-button
        :loading="loading"
        color="primary"
        :disabled="loading"
        @click="submit"
      >
        Send
      </base-button>
    </div>
  </base-modal>
</template>

<script>
import DetectRTC from 'detectrtc';
import { mapState, mapActions, mapGetters, mapMutations } from 'vuex';
import 'lodash';
import { format } from 'date-fns';
import { zonedTimeToUtc } from 'date-fns-tz';

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      loading: false,
      showInfo: false,
      isValid: false,
      form: {
        message: '',
        type: 1,
        priority: 3,
      },
      ticketAppointment: null,
      detectRTCResult: null,
      loadingList: false,
    };
  },

  computed: {
    ...mapState('user', ['user']),
    ...mapState('appointment', ['appointment']),
    ...mapState('opentok', ['audioDevices', 'videoDevices']),
    ...mapState(['showTimeline', 'showSupportTicket']),
    ...mapState('support', ['types', 'priorityList']),
    ...mapGetters('appointment', {
      recentAppointments: 'getRecentAppointments',
    }),
    typeOptions() {
      return this.types.filter(type => type.id);
    },
    priorityOptions() {
      return this.priorityList.filter(prio => prio.id);
    },
    currentUrl() {
      return window.location.href;
    },
    browser() {
      return `${this.detectRTCResult.browser.name} ${this.detectRTCResult.browser.fullVersion}`;
    },
    os() {
      return `${this.detectRTCResult.osName} ${this.detectRTCResult.osVersion}`;
    },
    appointmentId() {
      return this.$router.currentRoute?.params?.id;
    },
    filteredAppointments() {
      return this.filteredRecentAppointments.map(appointment => {
        return {
          id: appointment.id,
          label: `${appointment.animal.name}, ${this.formatTime(appointment)}`,
        };
      });
    },
    selectedAppointment: {
      get() {
        return this.ticketAppointment;
      },
      set(val) {
        this.ticketAppointment = val;
      },
    },
    filteredRecentAppointments() {
      if (!this.recentAppointments) return [];
      const appointments = this.recentAppointments.slice();
      return appointments.sort((a, b) => {
        const dateA = new Date(a.booking.booking_datetime);
        const dateB = new Date(b.booking.booking_datetime);
        return dateA - dateB;
      });
    },
  },
  watch: {
    id() {
      this.setActiveAppointment(this.id);
    },
  },
  async mounted() {
    this.detectRTC();
    this.getDevices();
    this.loadingList = true;
    await this.fetchRecentAppointments();
    this.loadingList = false;

    if (this.id) this.setActiveAppointment(this.id);
  },
  methods: {
    ...mapActions('opentok', ['getDevices']),
    ...mapActions(['sendSupportTicket']),
    ...mapMutations(['toggleSupportTicketModal']),
    ...mapActions('appointment', ['fetchRecentAppointments']),
    ...mapActions('support', ['addTicket']),

    formatTime(appointment) {
      return format(
        zonedTimeToUtc(
          appointment.booking.booking_datetime,
          appointment.booking.timezone
        ),
        'HH:mm'
      );
    },
    setActiveAppointment(id) {
      this.ticketAppointment = this.filteredAppointments.find(appointment => {
        return appointment.id === +id;
      });
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) return;
      this.loading = true;

      const payload = {
        ...this.form,
        appointmentId: this.ticketAppointment?.id || null,
        os: this.os,
        browser: this.browser,
        debug: JSON.stringify(this.detectRTCResult),
        url: this.currentUrl,
      };

      this.addTicket(payload)
        .then(() => {
          this.loading = false;
          this.$emit('close');
          this.$notify({
            group: 'primary',
            title: 'Ticket submitted',
            text: 'One of our staff will answer your ticket if we need more information or need to get back to you',
          });
          this.reset();
        })
        .catch(e => {
          this.loading = false;
          this.$notify({
            group: 'error',
            title: 'Something went wrong',
            text: e.message,
          });
        });
    },
    reset() {
      this.form = {
        message: '',
        type: 1,
        priority: 3,
      };
      this.ticketAppointment = null;
    },
    detectRTC() {
      /*eslint-disable */
      DetectRTC.load(() => {
        DetectRTC.hasWebcam; // (has webcam device!)
        DetectRTC.hasMicrophone; // (has microphone device!)
        DetectRTC.hasSpeakers; // (has speakers!)
        DetectRTC.isScreenCapturingSupported; // Chrome, Firefox, Opera, Edge and Android
        DetectRTC.isSctpDataChannelsSupported;
        DetectRTC.isRtpDataChannelsSupported;
        DetectRTC.isAudioContextSupported;
        DetectRTC.isWebRTCSupported;
        DetectRTC.isDesktopCapturingSupported;
        DetectRTC.isMobileDevice;

        DetectRTC.isWebSocketsSupported;
        DetectRTC.isWebSocketsBlocked;
        // DetectRTC.checkWebSocketsSupport(callback);

        DetectRTC.isWebsiteHasWebcamPermissions; // getUserMedia allowed for HTTPs domain in Chrome?
        DetectRTC.isWebsiteHasMicrophonePermissions; // getUserMedia allowed for HTTPs domain in Chrome?

        DetectRTC.audioInputDevices; // microphones
        DetectRTC.audioOutputDevices; // speakers
        DetectRTC.videoInputDevices; // cameras

        DetectRTC.osName;
        DetectRTC.osVersion;

        DetectRTC.browser.name === 'Edge' || 'Chrome' || 'Firefox';
        DetectRTC.browser.version;
        DetectRTC.browser.isChrome;
        DetectRTC.browser.isFirefox;
        DetectRTC.browser.isOpera;
        DetectRTC.browser.isIE;
        DetectRTC.browser.isSafari;
        DetectRTC.browser.isEdge;

        DetectRTC.browser.isPrivateBrowsing; // incognito or private modes

        DetectRTC.isCanvasSupportsStreamCapturing;
        DetectRTC.isVideoSupportsStreamCapturing;

        this.detectRTCResult = DetectRTC;

      });
    },
  },
};
</script>
