interface TopNavButtonDefinition {
  htmlClasses: string | null;
  tooltip: string;
  isExact: boolean;
  isPingVisible: boolean;
  toSlug: string | null;
  isDisplayed: string | boolean;
  onClick: string | null;
  icon?: string | null;
}

const topNavButtonDefs: TopNavButtonDefinition[] = [
  {
    htmlClasses: 'w-full',
    tooltip: 'Submit a support Ticket',
    toSlug: null,
    isExact: false,
    isPingVisible: false,
    onClick: 'showOrHideSupportTicket',
    isDisplayed: true,
    icon: 'lifebuoy',
  },
  {
    htmlClasses: null,
    tooltip: 'Admin',
    toSlug: 'schedule-dashboard',
    isExact: false,
    isPingVisible: false,
    onClick: null,
    isDisplayed: 'displayAdminMenu',
    icon: 'settings',
  },
];

const filteredTopNavButtonDefs: TopNavButtonDefinition[] = topNavButtonDefs;

export default filteredTopNavButtonDefs;
