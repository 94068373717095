var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.imageLoadError && _vm.avatar
    ? _c("img", {
        staticClass: "rounded-full object-cover",
        class: "h-" + _vm.size * 4 + " w-" + _vm.size * 4,
        attrs: { src: _vm.avatar, alt: "Profile picture" },
        on: {
          error: function ($event) {
            _vm.imageLoadError = true
          },
        },
      })
    : _c(
        "font-awesome-layers",
        { staticClass: "fa-lg", style: { fontSize: _vm.size + "em" } },
        [
          _c("font-awesome-icon", {
            attrs: { icon: "circle", color: "#b8c2cc" },
          }),
          _c("font-awesome-icon", {
            attrs: { icon: "user-md", transform: "shrink-6", color: "#ffffff" },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }