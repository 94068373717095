var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "fixed z-20 flex h-10 w-full flex-grow justify-between border-b bg-white md:border-b-0",
    },
    [
      _c("div", {
        staticClass: "absolute hidden w-full border-b md:block",
        staticStyle: { top: "100%", left: "56.25px" },
      }),
      _c(
        "div",
        { staticClass: "flex pl-5 pt-2 pb-1" },
        [
          _c(
            "router-link",
            {
              staticClass: "remove-router-style",
              attrs: { to: "/vetpanel", exact: true },
            },
            [
              _c("img", {
                staticClass: "w-35 h-6",
                attrs: { src: require("@/assets/svg/firstvet_logo.svg") },
              }),
            ]
          ),
        ],
        1
      ),
      _c(
        "nav",
        { staticClass: "mr-5 flex flex-row" },
        [
          _c("admin-menu", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isAdmin,
                expression: "isAdmin",
              },
            ],
          }),
          _vm._l(_vm.topNavDefinitions, function (componentHash, index) {
            return _c(
              "div",
              {
                key: index,
                staticClass: "flex-col",
                on: {
                  click: function ($event) {
                    return _vm.setActiveClass(
                      componentHash.routerLinkActive,
                      index
                    )
                  },
                },
              },
              [
                _c(
                  "nav-button",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.getValueFromDefinitionKey(
                          componentHash,
                          "isDisplayed"
                        ),
                        expression:
                          "getValueFromDefinitionKey(componentHash, 'isDisplayed')",
                      },
                      {
                        name: "tooltip",
                        rawName: "v-tooltip.right",
                        value: { content: _vm.getToolTip(componentHash) },
                        expression: "{ content: getToolTip(componentHash) }",
                        modifiers: { right: true },
                      },
                    ],
                    class: ["py-2"],
                    attrs: {
                      color: "dark",
                      exact: componentHash.isExact,
                      to: componentHash.toSlug
                        ? { name: componentHash.toSlug }
                        : null,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.callClickFunction(componentHash)
                      },
                    },
                  },
                  [
                    componentHash.icon
                      ? _c("fv-icon", {
                          attrs: { icon: componentHash.icon, size: "md" },
                        })
                      : _vm._e(),
                    _vm.isMessageNotificationDisplayed(componentHash)
                      ? _c("span", {
                          staticClass:
                            "-mt-1 h-3 w-3 animate-pulse rounded-full bg-primary",
                        })
                      : _vm._e(),
                    componentHash.isPingVisible
                      ? _c("div", { staticClass: "relative" }, [
                          _c("span", {
                            staticClass:
                              "absolute right-0 top-0 h-2 w-2 animate-ping rounded-full bg-primary",
                          }),
                        ])
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c("ProfileMenu"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }