<template>
  <div>
    <div class="inline-flex items-center">
      <div class="relative">
        <button
          class="inline-flex items-center py-2 px-3 text-gray-900 hover:text-action"
          :class="digitalClinicId ? 'cursor-default' : 'focus:outline-none '"
          :disabled="!!digitalClinicId"
          @click="openMenu = !openMenu"
        >
          <span
            v-if="country"
            class="mr-1 inline-flex select-none items-center"
          >
            <span class="hidden text-sm md:block">{{ country.name }}</span>
            <span class="ml-1">
              <img
                class="h-5 w-7"
                :src="
                  require('@/assets/svg/flags/' +
                    country.localization.toLowerCase() +
                    '.svg')
                "
              />
            </span>
          </span>
          <span v-if="!country">
            <base-spinner class="mx-auto text-sm" />
          </span>
          <fv-icon
            v-if="!digitalClinicId"
            icon="small-chevron-down"
            :class="['svg-fill h-3 w-3']"
          />
        </button>
        <nav
          v-if="openMenu"
          class="absolute top-0 right-0 z-50 mt-10 flex w-48 flex-col rounded bg-white py-2 text-sm shadow"
        >
          <button
            v-for="country in countries"
            :key="country.id"
            class="py-3 px-4 text-left font-semibold text-gray-800 hover:bg-gray-100"
            :class="{ 'bg-gray-200 ': country.id === countryId }"
            @click="selectCountry(country.id)"
          >
            {{ country.name }}
          </button>
        </nav>
      </div>
    </div>
    <div
      v-show="openMenu"
      class="bg-color-600 fixed inset-0 z-10 transition-opacity ease-in-out"
      @click="openMenu = false"
    />
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from 'vuex';

export default {
  data() {
    return {
      openMenu: false,
    };
  },
  computed: {
    ...mapState('country', ['countries']),
    ...mapState('admin', ['countryId']),
    ...mapGetters('admin', ['country']),
    ...mapGetters('admin/digital-clinic', {
      digitalClinicId: 'getDigitalClinicId',
    }),
  },

  methods: {
    ...mapMutations('admin', ['setCountry']),
    selectCountry(countryId) {
      this.setCountry(countryId);
      this.openMenu = false;
    },
  },
};
</script>
