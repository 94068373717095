export default {
  schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
  editable: false,
  selectable: false,
  allDaySlot: false,
  aspectRatio: 3,
  displayEventEnd: false,
  nowIndicator: true,
  initialView: 'timeGridDay',
  firstDay: 1,
  locale: 'en-GB',
  slotLabelFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hourCycle: 'h23', // not in the DateTimeFormatOptions interface
  },
  slotLabelInterval: '01:00',
  slotDuration: '00:15',
  eventTimeFormat: {
    hour: '2-digit',
    minute: '2-digit',
    meridiem: false,
    hour12: false,
    hourCycle: 'h23',
  },
  height: 'auto',
  allDayText: '',
};
